import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import GenericLink from '../shared/Link'
import MediaItem from '../shared/MediaItem'
import {
  MediaImage,
  Paragraph,
  TeaserListParagraph,
  TeaserParagraph,
} from '../../types/paragraphs'
import type {Response} from '../../types/response'

type Props = {
  paragraph: Paragraph
  response: Response | null
}

const TeaserList = ({paragraph, response}: Props) => {
  const paragraphFull = response?.included?.find(
    el => el.id === paragraph.id,
  ) as TeaserListParagraph | undefined
  if (!paragraphFull) {
    return null
  }
  const background = paragraphFull.attributes.field_teaser_background
  const layout = paragraphFull.attributes.field_teaser_layout
  const teasers = response?.included?.filter(el =>
    paragraphFull.relationships.field_teaser.data.find(
      teaser => teaser.id === el.id,
    ),
  ) as TeaserParagraph[] | undefined

  return (
    <section
      className={`section-box c-teaser-list c-teaser-list-${layout} section--bg-${background}`}
    >
      <Container fluid>
        <Row>
          <Col>
            <div className="d-lg-flex flex-column align-items-center c-teaser-list__section">
              {paragraphFull.attributes.field_title && (
                <h2 className="c-teaser-list__heading">
                  {' '}
                  {paragraphFull.attributes.field_title}
                </h2>
              )}

              <div className="c-teaser-list__cards">
                {teasers?.map(teaser => {
                  const media = response?.included?.find(
                    el => el.id === teaser.relationships.field_media.data?.id,
                  ) as MediaImage
                  return (
                    <GenericLink
                      link={teaser.attributes.field_button}
                      extraClass="c-teaser-list__card"
                      key={teaser.id}
                    >
                      <div
                        className={
                          teaser.attributes.field_button
                            ? 'h-100 d-flex flex-column'
                            : 'c-teaser-list__card'
                        }
                      >
                        <div className="c-teaser-list__card-label">
                          {media && (
                            <>
                              {layout === 'data' || layout === 'usp' ? (
                                <MediaItem
                                  item={media}
                                  response={response}
                                  wrapperClass="image-wrapper"
                                  width={
                                    media.relationships.thumbnail.data.meta
                                      .width! /
                                    (media.relationships.thumbnail.data.meta
                                      .height! /
                                      (layout === 'data' ? 50 : 100))
                                  }
                                  height={layout === 'data' ? 50 : 100}
                                />
                              ) : (
                                <MediaItem
                                  item={media}
                                  response={response}
                                  height={35}
                                  width={
                                    media.relationships.thumbnail.data.meta
                                      .width! /
                                    (media.relationships.thumbnail.data.meta
                                      .height! /
                                      35)
                                  }
                                />
                              )}
                            </>
                          )}
                          {layout === 'data' &&
                            teaser.attributes.field_data && (
                              <span className="c-teaser-list__card-data">
                                {teaser.attributes.field_data}
                              </span>
                            )}
                          <span className="c-teaser-list__card-title">
                            {teaser.attributes.field_title}
                          </span>
                        </div>

                        <p className="c-teaser-list__card-text">
                          {teaser.attributes.field_short_description}
                        </p>

                        {teaser.attributes.field_button && (
                          <span className="c-teaser-list__card-button">
                            {teaser.attributes.field_button.title}
                          </span>
                        )}
                      </div>
                    </GenericLink>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TeaserList
